//import recognition from "./reco";
import PepsiReco from "./PepsiReco";

document.addEventListener("DOMContentLoaded", function (event) {
  // create a PlayCanvas application
  var canvas = window.document.getElementById("application");
  var app = new pc.Application(canvas, {});
  var pepsiReco = null;
  app.start();

  // fill the available space at full resolution
  app.setCanvasFillMode(pc.FILLMODE_FILL_WINDOW);
  app.setCanvasResolution(pc.RESOLUTION_AUTO);

  // ensure canvas is resized when window changes size
  window.addEventListener("resize", function () {
    app.resizeCanvas();
  });

  // create box entity
  var cube = new pc.Entity("cube");
  cube.addComponent("model", {
    type: "box",
  });

  // create camera entity
  var camera = new pc.Entity("camera");
  camera.addComponent("camera", {
    clearColor: new pc.Color(0.1, 0.1, 0.1),
  });

  // create directional light entity
  var light = new pc.Entity("light");
  light.addComponent("light");

  // add to hierarchy
  app.root.addChild(cube);
  app.root.addChild(camera);
  app.root.addChild(light);

  // set up initial positions and orientations
  camera.setPosition(0, 0, 3);
  light.setEulerAngles(45, 0, 0);

  // register a global update event
  app.on("update", function (deltaTime) {
    cube.rotate(10 * deltaTime, 20 * deltaTime, 30 * deltaTime);
  });

  // After XR has fully loaded, open the camera feed and start displaying AR.
  const runOnLoad =
    ({ pcCamera, pcApp }, extramodules) =>
    () => {
      XR8.XrController.configure({ disableWorldTracking: true });
      XR8.PlayCanvas.runXr({ pcCamera, pcApp }, extramodules);
    };

  // Find the camera in the playcanvas scene, and tie it to the motion of the user's phone in the
  // world.
  const pcCamera = XRExtras.PlayCanvas.findOneCamera(app.root);

  XRExtras.Loading.showLoading({
    onxrloaded: runOnLoad({ pcCamera, pcApp: app }, [
      // Optional modules that developers may wish to customize or theme.
      XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
      XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
    ]),
  });

  const handleCameraStatusChange = function handleCameraStatusChange(detail) {
    if (detail.status === "hasVideo") {
      //hasVideo can fire multiple times while being set up
      if (pepsiReco == null) {
        // allow extra frame for data from video (occasionally won't have data available causing TF error)
        setTimeout(function () {
          console.log("initialising PepsiReco");
          var video = document.getElementsByTagName("video")[0];
          pepsiReco = new PepsiReco(video, true);
          pepsiReco.start();
        }, 40);
      }
    }
  };
  app.on("xr:camerastatuschange", handleCameraStatusChange, this);
});
