/* 
NOTE TO UNBND: You (hopefully!) shouldn't need to edit this class to have the bottle recognition work. 
Contact ben@bferns.com if something seems broken.
*/

import * as automl from "@tensorflow/tfjs-automl";
import * as tf from "@tensorflow/tfjs";
import { Pane } from "tweakpane";

export default class PepsiReco {
  PROB_THRESHOLD = 0.8;
  FRAME_SKIP = 4;

  requestAnimationFrameId = null;
  videoEl = null;
  model = null;
  pane = null;
  frameCount = -1;
  resultsPage = null;
  rawOutputPage = null;
  predictionData = {};
  resultsData = {
    best_prob: -1,
    estimate: "none",
    best_label: "none",
    running: false,
  };

  constructor(videoEl, debug = false) {
    this.videoEl = videoEl;
    this.debug = debug;
    if (debug) {
      this.pane = new Pane({ title: "Debug" });
      const tab = this.pane.addTab({
        pages: [{ title: "Results" }, { title: "Raw Output" }],
      });
      this.resultsPage = tab.pages[0];
      this.rawOutputPage = tab.pages[1];
    }
  }

  async bottleDetectionFrame(repeat = true) {
    // load model if we haven't already
    if (this.model == null || this.model == undefined) {
      this.model = await automl.loadImageClassification("public/model.json");
    }

    // video data may not be available. wait if so to avoid TF error
    if (this.videoEl.readyState < 3) {
      //console.log("video not started, waiting");
      return;
    }

    //skip if in continuous mode and frameskip is enabled, increment framecount either way
    if (++this.frameCount % this.FRAME_SKIP !== 0 && repeat) {
      this.requestAnimationFrameId = requestAnimationFrame(
        this.bottleDetectionFrame.bind(this)
      );
      return;
    }

    this.frameCount = 0; //avoid ever-increasing framecount

    const predictions = await this.model.classify(this.videoEl);

    //setup data for prediction pass
    let firstRun = false;
    this.resultsData.best_prob = -1;
    this.resultsData.estimate = "none";
    this.resultsData.best_label = "none";

    if (Object.keys(this.predictionData).length == 0) {
      firstRun = true;
    }

    predictions.forEach((p) => {
      if (this.predictionData[p.label] == undefined) {
        this.predictionData[p.label] = {};
      }

      this.predictionData[p.label]["confidence"] = p.prob;
      if (p.prob > this.resultsData.best_prob) {
        this.resultsData.best_label = p.label;
        this.resultsData.best_prob = p.prob;
      }

      if (firstRun && this.debug) {
        const folder = this.rawOutputPage.addFolder({
          title: p.label,
        });

        folder.addMonitor(this.predictionData[p.label], "confidence", {
          interval: 100,
        });
      }
    });

    //check if the best result is above our min threshold
    if (this.resultsData.best_prob > this.PROB_THRESHOLD) {
      this.resultsData.estimate = this.resultsData.best_label;
    }

    if (firstRun && this.debug) {
      this.resultsPage.addMonitor(this.resultsData, "best_label", {
        interval: 100,
        label: "best",
      });
      this.resultsPage.addMonitor(this.resultsData, "best_prob", {
        interval: 100,
        label: "prob",
      });
      this.resultsPage.addSeparator();
      this.resultsPage.addMonitor(this.resultsData, "estimate", {
        interval: 100,
        label: "estimate",
      });
    }

    if (repeat) {
      this.requestAnimationFrameId = requestAnimationFrame(
        this.bottleDetectionFrame.bind(this)
      );
      this.resultsData.running = true;
    }

    return Promise.resolve();
  }

  stop() {
    if (this.resultsData.running) {
      window.cancelAnimationFrame(this.requestAnimationFrameId);
      this.resultsData = {
        best_prob: -1,
        estimate: "none",
        best_label: "none",
        running: false,
      };
    } else {
      console.error("PepsiReco: detection is already stopped");
    }
  }

  async start() {
    if (!this.resultsData.running) {
      return this.bottleDetectionFrame();
    } else {
      console.error("PepsiReco: detection is already running");
      return Promise.resolve();
    }
  }

  getResults() {
    return this.resultsData;
  }

  loadModel() {
    return automl.loadImageClassification("public/model.json");
  }

  async detectOnce() {
    await this.bottleDetectionFrame(false);
    this.resultsData.running = false;
    return getResults();
  }
}
